<template>
  <div>
    <Topbar />
    <Navbar />
    <Banner />
    <BlogList />
    <Footer />
    <!-- <CartButton /> -->
    <SocialChat icon :attendants="attendants">
      <p slot="header" class="font-weight-bolder">
        Click on one of our attendants below to chat on WhatsApp.
      </p>
      <template v-slot:button>
        <img
          src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp"
          aria-hidden="true"
        />
      </template>
      <small class="font-weight-bolder" slot="footer">Open: 24 hrs </small>
    </SocialChat>
  </div>
</template>

<script>
import Topbar from "@/components/home/Topbar.vue";
import Navbar from "@/components/home/Navbar.vue";
import Banner from "@/components/home/Banner.vue";
import BlogList from "@/components/blog/BlogList.vue";
import Footer from "@/components/home/Footer.vue";
// import CartButton from "@/components/floatingButton/CartButton.vue";
import { SocialChat } from "vue-social-chat";

export default {
  components: {
    SocialChat,
    CartButton,
    Topbar,
    Navbar,
    Banner,
    // Footer,
    BlogList,
  },
  data() {
    return {
      attendants: [
        {
          app: "whatsapp",
          label: "Medask Tours",
          name: "Customer Support",
          number: "923196048487",
          avatar: {
            src: "https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4",
            alt: "Alan Ktquez avatar",
          },
        },
      ],
    };
  },
};
</script>

<style>
:root {
  --vsc-bg-header: #091e3d;
  --vsc-bg-footer: #06bd46;
  --vsc-text-color-header: white;
  --vsc-text-color-footer: white;
  --vsc-bg-button: #06bd46;
  --vsc-text-color-button: white;
  --vsc-outline-color: #091e3d;
  --vsc-border-color-bottom-header: transparent;
  --vsc-border-color-top-footer: #f3f3f3;
}

.social-chat-header {
  background-color: var(--vsc-bg-header) !important;
  color: var(--vsc-text-color-header) !important;
}

.social-chat-footer {
  background-color: var(--vsc-bg-footer);
  color: var(--vsc-text-color-footer);
}

.social-chat-button {
  background-color: var(--vsc-bg-button);
  color: var(--vsc-text-color-button);
}

.social-chat-outline {
  outline-color: var(--vsc-outline-color);
}

.social-chat-header {
  border-bottom: 1px solid var(--vsc-border-color-bottom-header);
}

.social-chat-footer {
  border-top: 1px solid var(--vsc-border-color-top-footer);
}
</style>

<template>
  <div class="my-bg py-1">
    <b-container>
      <h1 class="font-weight-bolder text-colorBlue pt-5 text-center">
        Our <span class="text-colorGreen"> Blogs</span>
      </h1>
      <b-row class="mt-5 px-5">
        <b-col
          md="3"
          class="d-flex align-items-stretch justify-content-center mb-1"
          style="position: relative"
          v-for="blog in blogs"
          :key="blog.id"
        >
          <div class="doctor-card d-flex flex-column">
            <div class="doctor-image-container">
              <!-- Najaf -->
              <img
                :src="blog.image"
                alt="Blog Image"
                style="width: 100%; height: 300px"
              />
            </div>

            <div
              class="bg-colorBlue py-4 px-2"
              style="
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                height: auto;
              "
            >
              <h4 class="font-weight-bolder text-white">
                {{ blog.category_name }}
              </h4>
              <h5 class="text-white font-weight-normal multi-line-truncate">
                {{ blog.intro }}
              </h5>
              <b-button
                :to="{ name: 'Blog', query: { id: blog.id } }"
                class="mt-3"
                variant="light"
                >Read Blog</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      blogs: [],
      showModal: false,
      viewAll: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      getBlogPublic: "appData/getBlogPublic",
    }),
    async fetchData() {
      try {
        const response = await this.getBlogPublic();
        this.blogs = response.data;
        console.log(this.blogs);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style>
.my-bg {
  background: url("~@/assets/images/images/specialists-overlay.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Adjust line height if needed */
  max-height: calc(1.5em * 3); /* 1.5em * number of lines to show */
}
.doctor-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px; /* Adjust max-width as needed */
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.doctor-image-container {
  flex: 1;
}
</style>
